import axios from "axios";

export const fetchData = async (endpoint: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}${endpoint}`
  );

  if (!response.status) {
    throw new Error("Network response was not ok");
  }

  return response.data;
};

export const postData = async (endpoint: string, data: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}${endpoint}`,
    data
  );
  if (!response.status) {
    throw new Error("Network response was not ok");
  }
  return response.data;
};
