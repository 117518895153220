import "./login.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { postData } from "../../utils/fetchData";
import { useAuth } from "../../utils/AuthContext";

const BookingDetail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { login } = useAuth();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .min(5, "Enter a username")
      .max(5, "Enter a username")
      .required("Required"),
    password: Yup.string()
      .min(15, "Enter a password")
      .max(15, "Enter a password")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { token } = await postData("user/login", values);

        document.cookie = `nailday-session_token=${token}`;

        login();

        navigate("/");
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    },
  });

  const isButtonDisabled =
    Object.keys(formik.errors).length > 0 || !formik.dirty;

  return (
    <div className="login">
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="username"
          name="username"
          label={"Username"}
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          margin={"dense"}
          style={{
            height: "85px",
          }}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label={"Password"}
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          margin={"dense"}
          style={{
            height: "85px",
          }}
        />
        {error && (
          <>
            <div style={{ color: "red" }} className="error">
              Username or Password is wrong!
            </div>
            <br />
          </>
        )}
        <Button
          disabled={isButtonDisabled}
          color="success"
          variant="contained"
          fullWidth
          type="submit"
        >
          {loading ? <ClipLoader size={20} color="white" /> : "LOGIN"}
        </Button>
      </form>
    </div>
  );
};

export default BookingDetail;
